:root {
  --primary-color: #3498db;
  --light-blue: #3498db59;
  --secondary-color: #2e63cc;
  --accent-color: #e74c3c;
  --background-color: #f0f0f0;
  --text-color: #333;
  --border-color: #000;
  --pink: #ff5bdc;
  --header-font-size: 2rem;
  --subheader-font-size: 1.5rem;
  --body-font-size: 1rem;
  --small-font-size: .75rem;
}

@media (width >= 768px) {
  :root {
    --header-font-size: 2.5rem;
    --subheader-font-size: 2rem;
    --body-font-size: 1.2rem;
    --small-font-size: .875rem;
  }
}

@media (width >= 1024px) {
  :root {
    --header-font-size: 3rem;
    --subheader-font-size: 2.5rem;
    --body-font-size: 1.4rem;
    --small-font-size: 1rem;
  }
}

@media (width >= 1440px) {
  :root {
    --header-font-size: 3.5rem;
    --subheader-font-size: 3rem;
    --body-font-size: 1.6rem;
    --small-font-size: 1.125rem;
  }
}

:root {
  --spacing-xlarge: 4em;
  --spacing-large: 2.5em;
  --spacing-medium: 1.5em;
  --spacing-small: 1em;
  --spacing-xsmall: .5em;
}

@media (width >= 768px) {
  :root {
    --spacing-xlarge: 4.5em;
    --spacing-large: 3em;
    --spacing-medium: 1.75em;
    --spacing-small: 1.2em;
    --spacing-xsmall: .75em;
  }
}

@media (width >= 1024px) {
  :root {
    --spacing-xlarge: 5em;
    --spacing-large: 3.5em;
    --spacing-medium: 2em;
    --spacing-small: 1.5em;
    --spacing-xsmall: 1em;
  }
}

@media (width >= 1440px) {
  :root {
    --spacing-xlarge: 6em;
    --spacing-large: 4em;
    --spacing-medium: 2.5em;
    --spacing-small: 1.75em;
    --spacing-xsmall: 1.25em;
  }
}

main {
  max-width: 100%;
}

@media (width >= 1024px) {
  main {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  main {
    max-width: 1200px;
  }
}

@media (width >= 1600px) {
  main {
    max-width: 1400px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  display: flex;
}

h1 {
  font-size: var(--header-font-size);
}

h2 {
  font-size: var(--subheader-font-size);
}

p {
  font-size: var(--body-font-size);
}

.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-style: normal;
  font-weight: 400;
}

.small-text {
  font-size: var(--small-font-size);
}

button {
  padding: var(--spacing-small) var(--spacing-xlarge);
  background-color: var(--pink);
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: none;
  border-radius: .25rem;
  font-size: 1.25rem;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
}

button:hover {
  background-color: var(--secondary-color);
}

button:focus {
  outline: 2px solid var(--secondary-color);
  outline-offset: 2px;
}

button ul a {
  list-style-type: none;
}

ul li a {
  color: var(--text-color);
  text-decoration: none;
}

footer, header, section {
  width: 100%;
  padding: 0;
}

@media (width >= 768px) {
  footer, header, section {
    padding-bottom: var(--spacing-medium);
  }
}

.header {
  padding: var(--spacing-xsmall);
  background-color: #f8f8f8;
  background-color: var(--background-color);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.container {
  margin-bottom: var(--spacing-medium);
  padding: var(--spacing-xsmall);
  box-sizing: border-box;
  border: 2px solid var(--border-color);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

@media (width >= 768px) {
  .container {
    padding: var(--spacing-large);
  }
}

section {
  opacity: 1;
  box-sizing: border-box;
  padding: var(--spacing-xsmall);
  transition: opacity .2s ease-in-out;
}

.hidden {
  opacity: 0;
}

.row-flex {
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-center {
  text-align: center;
}

.margin-bottom {
  margin-bottom: var(--spacing-large);
}

.margin-block {
  margin-block: var(--spacing-large);
}

.space-between {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.right-container, .left-container {
  flex-direction: column;
  display: flex;
}

header .header__title {
  font-size: var(--header-font-size);
  border: 1px solid #000;
  align-items: center;
  display: flex;
}

header .header__nav-links {
  padding-left: var(--spacing-large);
  padding-top: var(--spacing-large);
}

.header__nav-links {
  background-color: var(--background-color);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.header__nav-links li {
  color: var(--primary-color);
  list-style: none;
}

.header__nav-links li .header__nav-link {
  transition: all .5s;
}

.header__nav-links .projects-hidden {
  background-color: red;
  display: none;
}

@media (width >= 768px) {
  header .header__menu-icon {
    display: none;
  }

  header .header__nav-links {
    flex-direction: row;
    padding: 0;
    display: flex;
    position: static;
  }

  header .header__nav-links .header__nav-item {
    margin-inline: var(--spacing-small);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
  }

  header .header__nav-links .header__nav-item .header__nav-link {
    padding-inline: var(--spacing-medium);
    border: 2px soild red;
    font-size: var(--body-font-size);
    background-color: var(--primary-color);
    z-index: 2;
  }

  header .header__nav-links .header__nav-item .projects-content {
    background-color: var(--background-color);
    top: var(--spacing-small);
    padding-block: var(--spacing-small);
    z-index: 1;
    width: 100%;
    position: absolute;
    left: 0;
  }

  header .header__nav-links .header__nav-item .projects-content a {
    box-sizing: border-box;
    padding-bottom: 1em;
    display: block;
  }

  header .header__nav-links .header__nav-item .projects-content a:hover {
    background-color: var(--light-blue);
  }
}

.header__menu-icon {
  z-index: 1000;
}

.slider {
  max-width: 80%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.slider .slides {
  width: 100%;
  transition: transform .5s ease-in-out;
  display: flex;
}

.slider .slides img {
  object-fit: fill;
  object-position: center;
  flex-shrink: 0;
  height: auto;
}

.prev, .next {
  color: #fff;
  cursor: pointer;
  background-color: #00000080;
  border: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.footer__left-container {
  justify-content: center;
}

footer .right-container {
  border: 1px solid var(--border-color);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

footer .right-container a {
  border: 1px solid var(--border-color);
  aspect-ratio: 1;
  font-size: var(--subheader-font-size);
  margin: var(--spacing-xsmall);
  text-decoration: none;
  display: inline-block;
}

@media (width >= 768px) {
  footer .right-container a {
    font-size: var(--body-font-size);
  }
}

.goggins-container {
  background-color: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.goggins-container #gogginsVideo {
  width: auto;
  height: 100%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.goggins-click-container {
  background-color: #00ff2a;
  flex-direction: column;
  width: 60%;
  display: flex;
}

.goggins-click-wrap {
  justify-content: center;
  display: flex;
}

.empty-space {
  height: 10000px;
}

.big-arrow {
  font-size: 100px;
  animation: .5s infinite upDown;
}

@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

:root {
  --facebook-blue: #1f71e4;
  --facebook-red: #e41f1f;
  --facebook-new: black;
}

.facebook .header-container {
  margin-top: var(--spacing-large);
  flex-direction: row;
  justify-content: space-between;
}

.facebook .header-container h1 {
  color: var(--facebook-blue);
}

.facebook .container {
  margin-bottom: var(--spacing-large);
}

.facebook .no {
  flex-direction: row;
}

img {
  width: 100%;
  transition: all 1s;
}

.lazy-load {
  filter: blur(10px);
  transition: all 1s;
}

.small-img {
  width: 5em;
  height: 5em;
}

@keyframes wobble {
  0%, 100% {
    transform-origin: 50%;
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-3px)rotate(-4deg);
  }

  30% {
    transform: translateX(1.5px)rotate(4deg);
  }

  45% {
    transform: translateX(-1.5px)rotate(-2.22222deg);
  }

  60% {
    transform: translateX(.909091px)rotate(1.33333deg);
  }

  75% {
    transform: translateX(-.545455px)rotate(-.8deg);
  }
}

.wobble {
  width: 100px;
  height: 100px;
  animation: 1s infinite wobble;
}

.box {
  animation: 2s infinite boogie-box;
}

@keyframes boogie-box {
  0% {
    background: var(--facebook-red);
    transform: translate(1rem, -1rem);
  }

  12.5% {
    transform: translate(-1rem, 1rem);
  }

  25% {
    transform: translate(1rem, -1rem);
  }

  37.5% {
    transform: translate(1rem, 1rem);
  }

  50% {
    background: var(--facebook-blue);
    transform: translate(-1rem, -1rem);
  }

  62.5% {
    transform: translate(1rem, 1rem);
  }

  75% {
    transform: translate(-1rem, -1rem);
  }

  87.5% {
    transform: translate(-1rem, 1rem);
  }

  100% {
    background: var(--facebook-red);
    transform: translate(1rem, -1rem);
  }
}

.invisible {
  justify-content: space-between;
  display: none;
}

.wide-putin-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wide-putin-section .header-container {
  margin-block: var(--spacing-small);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  display: flex;
}

.wide-putin-section .header-container h2 {
  padding: var(--small-font-size);
  box-sizing: border-box;
  border: 1px solid #000;
  width: 100%;
  display: inline-block;
}

.wide-putin-section .header-container h2 span {
  box-sizing: border-box;
  font-size: var(--body-font-size);
  color: #000;
  background-color: var(--pink);
  margin-left: 6em;
  display: inline-block;
  transform: scaleX(4);
}

.wide-putin-section .container {
  border: none;
  margin: 0;
  padding: 0;
}

.wide-putin-section .wide-container {
  justify-content: center;
  align-items: center;
  width: 60vh;
  height: 60vh;
  display: flex;
  overflow: hidden;
}

.wide-putin-section .wide-img-container {
  background-image: url("wide.80a56d7b.png");
  background-position: center;
  background-size: 100% 100%;
  width: 60vh;
  height: 100%;
}

.wide-putin-section p {
  margin-top: var(--spacing-small);
}

.wide-putin-section .buttons-container button {
  font-size: var(--body-font-size);
  margin-inline: var(--spacing-small);
  margin-bottom: var(--spacing-small);
  background-color: #0000;
  padding: 0;
}

@media (width <= 768px) {
  .wide-putin-section .buttons-container button {
    font-size: var(--subheader-font-size);
  }
}

.svg-box {
  margin-top: var(--spacing-medium);
  box-sizing: border-box;
  border: 1px solid #000;
  width: 100%;
  max-width: 500px;
  height: auto;
}

.svg-box svg {
  width: 100%;
  height: auto;
  display: block;
}

.record-container {
  margin-top: var(--spacing-medium);
  border: 1px solid #000;
  width: 100%;
  max-width: 500px;
  height: 200px;
}
/*# sourceMappingURL=index.631cc5cb.css.map */
