/* Color Variables */
:root {
  --primary-color: #3498db;
  --light-blue: #3498db59 /* Primary Blue */;
  --secondary-color: #2e63cc; /* Secondary Green */
  --accent-color: #e74c3c; /* Accent Red */
  --background-color: #f0f0f0; /* Light Grey Background */
  --text-color: #333; /* Dark Text */
  --border-color: #000000; /* Light Grey Border */
  --pink: rgb(255, 91, 220);
}

:root {
  --header-font-size: 2rem; /* Default size for small devices (e.g., mobile phones) */
  --subheader-font-size: 1.5rem;
  --body-font-size: 1rem;
  --small-font-size: 0.75rem;
}

@media (min-width: 768px) {
  :root {
    --header-font-size: 2.5rem; /* Adjusted for tablets */
    --subheader-font-size: 2rem;
    --body-font-size: 1.2rem;
    --small-font-size: 0.875rem;
  }
}
@media (min-width: 1024px) {
  :root {
    --header-font-size: 3rem; /* Adjusted for desktops */
    --subheader-font-size: 2.5rem;
    --body-font-size: 1.4rem;
    --small-font-size: 1rem;
  }
}
@media (min-width: 1440px) {
  :root {
    --header-font-size: 3.5rem; /* Larger screens (e.g., large desktops) */
    --subheader-font-size: 3rem;
    --body-font-size: 1.6rem;
    --small-font-size: 1.125rem;
  }
}
:root {
  --spacing-xlarge: 4em; /* Extra large spacing for large screens */
  --spacing-large: 2.5em; /* Large spacing for medium to large screens */
  --spacing-medium: 1.5em; /* Medium spacing for tablets and larger screens */
  --spacing-small: 1em; /* Small spacing for mobile devices */
  --spacing-xsmall: 0.5em; /* Extra small spacing for mobile devices */
}

@media (min-width: 768px) {
  :root {
    --spacing-xlarge: 4.5em; /* Slightly larger for tablets */
    --spacing-large: 3em; /* Larger for tablets and smaller desktops */
    --spacing-medium: 1.75em; /* Slightly larger for better spacing on tablets */
    --spacing-small: 1.2em; /* Increased for tablets */
    --spacing-xsmall: 0.75em; /* Increased for tablets */
  }
}
@media (min-width: 1024px) {
  :root {
    --spacing-xlarge: 5em; /* Larger for desktops */
    --spacing-large: 3.5em; /* Increased for desktops */
    --spacing-medium: 2em; /* Larger for better spacing on desktops */
    --spacing-small: 1.5em; /* Increased for desktops */
    --spacing-xsmall: 1em; /* Increased for larger desktops */
  }
}
@media (min-width: 1440px) {
  :root {
    --spacing-xlarge: 6em; /* Extra large for large screens */
    --spacing-large: 4em; /* Large for very large screens */
    --spacing-medium: 2.5em; /* Medium for very large screens */
    --spacing-small: 1.75em; /* Increased for larger screens */
    --spacing-xsmall: 1.25em; /* Increased for very large screens */
  }
}
/* Base styles for the main section */
main {
  max-width: 100%; /* Ensure it doesn't exceed viewport width */
}

/* For larger screens */
@media (min-width: 1024px) {
  main {
    max-width: 960px; /* Suitable for larger tablets and smaller desktops */
  }
}
@media (min-width: 1200px) {
  main {
    max-width: 1200px; /* Common max width for large desktops */
  }
}
@media (min-width: 1600px) {
  main {
    max-width: 1400px; /* For very large screens */
  }
}
/* Reset Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px; /* Base font size for rem units */
}

body {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: var(--header-font-size);
}

h2 {
  font-size: var(--subheader-font-size);
}

p {
  font-size: var(--body-font-size);
}

.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}

.small-text {
  font-size: var(--small-font-size);
}

/* Example Button Styles */
button {
  padding: var(--spacing-small) var(--spacing-xlarge); /* 8px 25.6px */
  font-size: 1.25rem; /* 20px */
  background-color: var(--pink);
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease;
}

button:hover {
  background-color: var(--secondary-color);
}

button:focus {
  outline: 2px solid var(--secondary-color); /* Custom focus border color */
  outline-offset: 2px; /* Space between the border and the button */
}

button ul a {
  list-style-type: none;
}

ul li a {
  text-decoration: none;
  color: var(--text-color);
}

footer,
header,
section {
  width: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  footer,
  header,
  section {
    padding-bottom: var(--spacing-medium);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-xsmall);
  background-color: #f8f8f8;
  width: 100%;
  background-color: var(--background-color);
}

/* Basic Layout and Container Styles */
.container {
  margin-bottom: var(--spacing-medium); /* This sets margin on the bottom */
  padding: var(--spacing-xsmall); /* This sets padding on all four sides */
  box-sizing: border-box;
  width: 100%;
  border: 2px solid var(--border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .container {
    padding: var(--spacing-large); /* 20px padding on all sides */
  }
}

section {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  box-sizing: border-box;
  padding: var(--spacing-xsmall);
}

.hidden {
  opacity: 0;
}

.row-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.margin-bottom {
  margin-bottom: var(--spacing-large); /* 20px */
}

.margin-block {
  margin-block: var(--spacing-large);
}

.space-between {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.right-container {
  display: flex;
  flex-direction: column;
}

.left-container {
  display: flex;
  flex-direction: column;
}

/* start of code --- nav */
header .header__title {
  font-size: var(--header-font-size);
  display: flex;
  align-items: center;
  border: 1px solid black;
}
header .header__nav-links {
  padding-left: var(--spacing-large);
  padding-top: var(--spacing-large);
}

.header__nav-links {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  height: 100%;
  flex-direction: column;
  width: 100%;
}
.header__nav-links li {
  color: var(--primary-color);
  list-style: none;
}
.header__nav-links li .header__nav-link {
  transition: all 0.5s;
}
.header__nav-links .projects-hidden {
  display: none;
  background-color: red;
}

@media (min-width: 768px) {
  header .header__menu-icon {
    display: none;
  }
  header .header__nav-links {
    padding: 0;
    display: flex;
    flex-direction: row;
    position: static;
  }
  header .header__nav-links .header__nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: var(--spacing-small);
    flex-direction: column;
    position: relative;
  }
  header .header__nav-links .header__nav-item .header__nav-link {
    padding-inline: var(--spacing-medium);
    border: 2px soild red;
    font-size: var(--body-font-size);
    background-color: var(--primary-color);
    z-index: 2;
  }
  header .header__nav-links .header__nav-item .projects-content {
    position: absolute;
    background-color: var(--background-color);
    top: var(--spacing-small); /* Adjust this value as needed */
    left: 0;
    padding-block: var(--spacing-small);
    width: 100%; /* Matches width to parent */
    z-index: 1;
  }
  header .header__nav-links .header__nav-item .projects-content a {
    box-sizing: border-box;
    display: block;
    padding-bottom: 1em;
  }
  header .header__nav-links .header__nav-item .projects-content a:hover {
    background-color: var(--light-blue);
  }
}
.header__menu-icon {
  z-index: 1000;
}

.slider {
  position: relative;
  max-width: 80%;
  margin: auto;
  overflow: hidden; /* Hide overflow to ensure only one image is visible */
}
.slider .slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* Ensure it takes up the full width of the slider */
}
.slider .slides img {
  flex-shrink: 0;
  height: auto; /* Maintain aspect ratio */
  object-fit: fill; /* Maintain aspect ratio and cover the container */
  object-position: center; /* Center the image within the container */
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.footer__left-container {
  justify-content: center;
}

footer .right-container {
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
footer .right-container a {
  border: 1px solid var(--border-color);
  aspect-ratio: 1/1;
  display: inline-block;
  font-size: var(--subheader-font-size);
  text-decoration: none;
  margin: var(--spacing-xsmall);
}
@media (min-width: 768px) {
  footer .right-container a {
    font-size: var(--body-font-size);
  }
}

.goggins-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: 0;
}
.goggins-container #gogginsVideo {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto; /* Maintain aspect ratio */
  height: 100%;
}

.goggins-click-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: rgb(0, 255, 42);
}

.goggins-click-wrap {
  display: flex;
  justify-content: center;
}

.empty-space {
  height: 10000px;
}

.big-arrow {
  font-size: 100px;
  animation: upDown 0.5s infinite;
}

@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Move up by 20px */
  }
}
:root {
  --facebook-blue: rgb(31, 113, 228);
  --facebook-red: rgb(228, 31, 31);
  --facebook-new: black;
}

.facebook .header-container {
  margin-top: var(--spacing-large);
  flex-direction: row;
  justify-content: space-between;
}
.facebook .header-container h1 {
  color: var(--facebook-blue);
}
.facebook .container {
  margin-bottom: var(--spacing-large);
}
.facebook .no {
  flex-direction: row;
}

img {
  transition: all 1s;
  width: 100%;
}

.lazy-load {
  transition: all 1s;
  filter: blur(10px);
}

.small-img {
  width: 5em;
  height: 5em;
}

@keyframes wobble {
  0%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-3px) rotate(-4deg);
    transform: translateX(-3px) rotate(-4deg);
  }
  30% {
    -webkit-transform: translateX(1.5px) rotate(4deg);
    transform: translateX(1.5px) rotate(4deg);
  }
  45% {
    -webkit-transform: translateX(-1.5px) rotate(-2.2222222222deg);
    transform: translateX(-1.5px) rotate(-2.2222222222deg);
  }
  60% {
    -webkit-transform: translateX(0.9090909091px) rotate(1.3333333333deg);
    transform: translateX(0.9090909091px) rotate(1.3333333333deg);
  }
  75% {
    -webkit-transform: translateX(-0.5454545455px) rotate(-0.8deg);
    transform: translateX(-0.5454545455px) rotate(-0.8deg);
  }
}
.wobble {
  width: 100px;
  height: 100px;
  animation: wobble 1s infinite;
}

.box {
  animation: boogie-box 2s ease infinite;
}

@keyframes boogie-box {
  0% {
    background: var(--facebook-red);
    transform: translate(1rem, -1rem);
  }
  12.5% {
    transform: translate(-1rem, 1rem);
  }
  25% {
    transform: translate(1rem, -1rem);
  }
  37.5% {
    transform: translate(1rem, 1rem);
  }
  50% {
    background: var(--facebook-blue);
    transform: translate(-1rem, -1rem);
  }
  62.5% {
    transform: translate(1rem, 1rem);
  }
  75% {
    transform: translate(-1rem, -1rem);
  }
  87.5% {
    transform: translate(-1rem, 1rem);
  }
  100% {
    background: var(--facebook-red);
    transform: translate(1rem, -1rem);
  }
}
.invisible {
  display: none;
  justify-content: space-between;
}

.wide-putin-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.wide-putin-section .header-container {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-block: var(--spacing-small);
}
.wide-putin-section .header-container h2 {
  width: 100%;
  border: 1px solid black;
  padding: var(--small-font-size);
  box-sizing: border-box;
  display: inline-block;
}
.wide-putin-section .header-container h2 span {
  box-sizing: border-box;
  display: inline-block; /* Required for transform to work properly on text */
  font-size: var(--body-font-size);
  transform: scaleX(4); /* Adjust the value as needed */
  margin-left: 6em;
  color: rgb(0, 0, 0);
  background-color: var(--pink);
}
.wide-putin-section .container {
  padding: 0;
  margin: 0;
  border: none;
}
.wide-putin-section .wide-container {
  width: fit-content;
  height: fit-content;
  height: 60vh;
  width: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.wide-putin-section .wide-img-container {
  background-image: url(/imgs/wide.png);
  height: 100%;
  width: 60vh;
  background-size: 100% 100%;
  background-position: center;
}
.wide-putin-section p {
  margin-top: var(--spacing-small);
}
.wide-putin-section .buttons-container button {
  font-size: var(--body-font-size);
  margin-inline: var(--spacing-small);
  margin-bottom: var(--spacing-small);
  padding: 0;
  background-color: transparent;
}
@media (max-width: 768px) {
  .wide-putin-section .buttons-container button {
    font-size: var(--subheader-font-size);
  }
}

.svg-box {
  width: 100%;
  max-width: 500px;
  height: auto;
  border: 1px solid black;
  margin-top: var(--spacing-medium);
  box-sizing: border-box;
}

.svg-box svg {
  width: 100%;
  height: auto;
  display: block; /* Ensures no extra space is added */
}

.record-container {
  width: 100%;
  max-width: 500px;
  height: 200px;
  border: 1px solid black;
  margin-top: var(--spacing-medium);
}

/*# sourceMappingURL=main.css.map */
